import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Appointment, AppointmentStatus } from '../../model/appointment.model';
import { AppointmentService } from '../../service/appointment.service';
import { Patient } from '../../model/patient.model';
import { Nurse } from '../../model/nurse.model';

@Component({
  selector: 'app-appointment-detail',
  templateUrl: './appointment-detail.component.html',
  styleUrls: ['./appointment-detail.component.scss']
})
export class AppointmentDetailComponent implements OnInit {
  dialogTitle = 'Appointment Detail';
  appointment: Appointment;
  nurse: Nurse;
  patient: Patient;
  appointmentId: number;
  loading: boolean;
  constructor(private _matDialogRef: MatDialogRef<AppointmentDetailComponent>,
              @Inject(MAT_DIALOG_DATA) private _data: any,
              private _appointmentService: AppointmentService) {
    this.appointmentId = _data.appointmentId;
  }

  ngOnInit(): void {
    this.loading = true;
    if (this.appointmentId){
      this._appointmentService.getAppointmentDetail(this.appointmentId)
        .subscribe((data: any) => {
          this.appointment = data.appointment;
          this.nurse = data.nurse;
          this.patient = data.patient;
          this.loading = false;
        });
    }
  }

  closeDialoag(): void {
    this._matDialogRef.close();
  }

  getAppointmentStatus(appointmentStatus: any): string{
    switch (appointmentStatus) {
      case AppointmentStatus.pending:
        return 'Pending';
      case AppointmentStatus.completed:
        return 'Completed';
      case AppointmentStatus.cancelled:
        return 'Cancelled';
      case AppointmentStatus.missed:
        return 'Missed';
      default:
        return '';
    }
  }
}
