import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable()
export class AppointmentService {
  apiUrl = environment.api;
  reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
  });

  constructor(private _httpClient: HttpClient) {}

  getAppointments(): Observable<any>{
      return this._httpClient.get(`${this.apiUrl}/admin/appointment`, {headers: this.reqHeader});
  }

  getAppointmentDetail(id: number): Observable<any>{
    return this._httpClient.get(`${this.apiUrl}/admin/appointment/${id}`, {headers: this.reqHeader});
}
}
