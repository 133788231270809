import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort, MatDialog } from '@angular/material';
import { AppointmentService } from '../service/appointment.service';
import { AppointmentDetailComponent } from './appointment-detail/appointment-detail.component';
import { fuseAnimations } from '@fuse/animations';
import { Appointment } from '../model/appointment.model';

@Component({
  selector: 'app-appointment',
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class AppointmentComponent implements OnInit {
  appointments: any[];
  loading: boolean;

  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['appointmentNo', 'created_at', 'appointmentDate'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private _appointmentService: AppointmentService,
    private _matDialog: MatDialog) {
      this.dataSource = new MatTableDataSource(this.appointments);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
  }

  ngOnInit(): void {
    this.loading = true;
    this._appointmentService.getAppointments()
      .subscribe((data: any) => {
        this.loading = false;
        this.appointments = data.appointments;
        this.dataSource.data = this.appointments;
      }, error => console.log(error));
  }

  onClickAppointmentDetail(appointment: Appointment): void {
    this._matDialog.open(AppointmentDetailComponent, {
        panelClass: 'appointment-detail-dialog',
        width: '600px',
        data: {
          appointmentId: appointment.id
        }
    });
  }

  applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
