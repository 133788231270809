import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Nurse } from '../../model/nurse.model';
import { NurseService } from '../../service/nurse.service';

@Component({
  selector: 'app-nurse-detail',
  templateUrl: './nurse-detail.component.html',
  styleUrls: ['./nurse-detail.component.scss']
})
export class NurseDetailComponent implements OnInit {
  dialogTitle = 'Nurse Detail';
  nurse: any;
  constructor(private _matDialogRef: MatDialogRef<NurseDetailComponent>,
              @Inject(MAT_DIALOG_DATA) private _data: any,
              private _nurseService: NurseService) {
    this.nurse = _data.nurse || new Nurse();
  }

  ngOnInit(): void {
   // console.log(this.patient);
  }

  closeDialoag(): void {
    this._matDialogRef.close();
  }

  onClickSave(): void {
    this._nurseService.saveNurse(this.nurse)
      .subscribe((data: any) => {
        if (data.nurse) this._nurseService.updateNurse(data.nurse);
        this.closeDialoag();
      }, error => console.log(error));
  }
}
