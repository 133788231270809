import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id       : 'applications',
        title    : 'Applications',
        type     : 'group',
        children : [
            {
                id       : 'dashboard',
                title    : 'Dashboard',
                translate: 'NAV.DASHBOARD.TITLE',
                type     : 'item',
                url      : 'dashboard'
            },
            {
                id       : 'nurses',
                title    : 'Nurses',
                translate: 'NAV.NURSES.TITLE',
                type     : 'item',
                url      : 'nurses'
            },
            {
                id       : 'patients',
                title    : 'Patients',
                translate: 'NAV.PATIENTS.TITLE',
                type     : 'item',
                url      : 'patients'
            },
            {
                id       : 'appointments',
                title    : 'Appointments',
                translate: 'NAV.APPOINTMENTS.TITLE',
                type     : 'item',
                url      : 'appointments'
            },
            
            {
                id       : 'prescriptions',
                title    : 'Prescriptions',
                translate: 'NAV.PRESCRIPTIONS.TITLE',
                type     : 'item',
                url      : 'prescriptions'
            }
        ]
    }
];
