import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable()
export class Login2Service {
  apiUrl = environment.api
  constructor(private _httpClient: HttpClient) { }

  login(data: any): Observable<any>{
    return this._httpClient.post(`${this.apiUrl}/admin/auth/login`, data);
  }
}
